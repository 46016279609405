import React from 'react'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import * as styles from '../styles/pages/join-slack'
import { Button } from '../components'

export default function JoinSlack() {
  return (
    <PageLayout
      sharingTitle="Aito.ai - Join aito slack"
      description="Join the Aito community on Slack. Discuss with your peers or with anybody from the Aito team"
    >
      <Helmet>
        <title>Aito.ai - Join aito slack</title>
      </Helmet>
      <styles.Container>
        <styles.PageHeading>Join us on Slack</styles.PageHeading>
        <styles.SectionText>
          Click the button below to join our Slack community.
        </styles.SectionText>
        <a href="https://join.slack.com/t/aitoai/shared_invite/zt-b3yfk345-sF46techNLhgzcLfJIC5pg" target="_blank" rel="noopener noreferrer">
          <Button>
            Join Slack
          </Button>
        </a>
      </styles.Container>
    </PageLayout>
  )
}
